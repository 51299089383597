import dayjs from "dayjs";
import React, {useState} from "react";
import {Grow, Stack} from "@mui/material";
import TeamHistory from "../../../../components/datagrids/Competitive/TeamHistory";
import {targets, useReferentialContext} from "../../../../Context";
import WindowContainer from "../../../../components/layout/WindowContainer";
import FilterSet from "../../../../components/selectors/Filterset";

export default function TeamMatchHistory(props: {target: targets}) {
    const context = useReferentialContext();
    context.setCurrentTarget(props.target);
    const [from, setFrom] = useState(dayjs().subtract(3, 'month'));
    const [until, setUntil] = useState(dayjs());
    const [team, setTeam] = useState(props.target === targets.scrims ? context.teamsReferential.filter(t => t.team_name === context.user.team)[0] : context.selectedTeam);
    const [paramObject, setParamObject] = useState({} as any);


    return (
        <WindowContainer spacing={2}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={props.target} date__gte={from} setDateGte={setFrom} date__lt={until} setDateLt={setUntil} team={team} setTeam={setTeam}/>

            <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
                <Stack
                    direction="row"
                    sx={{
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <TeamHistory from={from.unix()} until={until.unix()} team={team ? team.team_name : ''} target={props.target} />
                </Stack>
            </Grow>
        </WindowContainer>
    );
}
